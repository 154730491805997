import { ClusterPackageConfigType } from '@/utils/packages'

type ClusterFeatures = {
  /**
   * Enable or disable if the usage of a cluster should be seen
   */
  canSeeUsage: boolean
  /**
   * Enable or disable if the customer can delete their cluster
   */
  canDeleteCluster: boolean
  /**
   * Enable or disable if the customer can update their cluster DNS name
   */
  canSetCustomDns: boolean
  /**
   * Enable or disable visibility of MQTT-WebSocket connections for the selected package type
   */
  canSeeWebSocketDetails: boolean
  /**
   * Enable or disable if the product has the api-access functionality
   */
  canVisitApiAccessPage: boolean
  /**
   * Enable or disable the integrations view for the selected package type
   */
  canVisitIntegrationsPage: boolean
  /**
   * Enable or disable the access management tab
   */
  canVisitAccessManagementPage: boolean
  /**
   * Enable or disable the getting started tab
   */
  canVisitGettingStartedPage: boolean
  /**
   * Enable or disable the webclient view for the selected package type
   */
  canVisitWebClientPage: boolean
  /**
   * Enable or disable the viewing of troubleshooting logs
   */
  canVisitTroubleShoot: boolean
}

const DEFAULT_CLUSTER_FEATURES: ClusterFeatures = {
  canVisitWebClientPage: false,
  canVisitIntegrationsPage: false,
  canSeeWebSocketDetails: false,
  canVisitAccessManagementPage: false,
  canVisitGettingStartedPage: false,
  canSetCustomDns: false,
  canSeeUsage: false,
  canDeleteCluster: false,
  canVisitApiAccessPage: false,
  canVisitTroubleShoot: false,
}

export const clusterFeatureList: Record<
  ClusterPackageConfigType,
  Partial<ClusterFeatures>
> = {
  [ClusterPackageConfigType.FREE]: {
    ...DEFAULT_CLUSTER_FEATURES,
    canSeeUsage: true,
    canSeeWebSocketDetails: true,
    canDeleteCluster: true,
    canVisitAccessManagementPage: true,
    canVisitIntegrationsPage: true,
    canVisitWebClientPage: true,
    canVisitGettingStartedPage: true,
  },
  [ClusterPackageConfigType.PAYG]: {
    ...DEFAULT_CLUSTER_FEATURES,
    canSeeUsage: true,
    canSeeWebSocketDetails: true,
    canDeleteCluster: true,
    canVisitAccessManagementPage: true,
    canVisitWebClientPage: true,
    canVisitGettingStartedPage: true,
  },
  [ClusterPackageConfigType.STARTER]: {
    ...DEFAULT_CLUSTER_FEATURES,
    canSeeUsage: true,
    canSetCustomDns: true,
    canDeleteCluster: true,
    canSeeWebSocketDetails: true,
    canVisitAccessManagementPage: true,
    canVisitIntegrationsPage: true,
    canVisitWebClientPage: true,
    canVisitApiAccessPage: true,
    canVisitGettingStartedPage: true,
    canVisitTroubleShoot: true,
  },
  [ClusterPackageConfigType.PROFESSIONAL]: {
    ...DEFAULT_CLUSTER_FEATURES,
  },
  [ClusterPackageConfigType.DEDICATED]: {
    ...DEFAULT_CLUSTER_FEATURES,
    canVisitApiAccessPage: true,
    canVisitIntegrationsPage: false,
    canVisitWebClientPage: true,
    canVisitTroubleShoot: true,
  },
  [ClusterPackageConfigType.STANDARD]: {
    ...DEFAULT_CLUSTER_FEATURES,
    canSeeUsage: true,
    canSeeWebSocketDetails: true,
    canDeleteCluster: true,
    canVisitAccessManagementPage: true,
    canVisitWebClientPage: true,
    canVisitGettingStartedPage: true,
  },
  [ClusterPackageConfigType.BUSINESS]: {
    ...DEFAULT_CLUSTER_FEATURES,
    canSeeUsage: true,
    canSeeWebSocketDetails: true,
    canDeleteCluster: true,
    canVisitAccessManagementPage: true,
    canVisitWebClientPage: true,
    canVisitGettingStartedPage: true,
  },
  [ClusterPackageConfigType.UNKNOWN]: {
    ...DEFAULT_CLUSTER_FEATURES,
  },
}
