import { ApiVersion, HTTPMethod, useFetchCall } from '@app/helpers/useFetchCall'
import * as React from 'react'

import {
  starterPackage,
  ClusterPackageConfigType,
  clusterPackages,
} from '@/utils/packages'

import * as ApiaryAPI from '@/generated/openapi/apiary'

import { AccountContext } from '../account/account'
import { OrganizationContext } from '../organization/organization'

import { Status } from '@/@types'
import { Cluster } from '@/@types/consoleApi.types'
import { ClusterCreateConfig } from '@/modules/cluster/hooks/usePostCluster'

type StoreState = {
  status: { get: Status; delete: Status; post: Status; specificCluster: Status }
  _clusters: Array<Cluster>
  current: null | Cluster
}

type ClusterStore = {
  getClusters: () => void
  createCluster: (_payload: ClusterCreateConfig) => void
  deleteCluster: (_clusterId: string) => void
  getSpecificCluster: (_clusterId: string, serverless: boolean) => void
  clusters: Record<ClusterPackageConfigType, Array<Cluster>>
  storeState: StoreState
}

const initialState = {
  status: {
    get: Status.Rest,
    post: Status.Rest,
    delete: Status.Rest,
    specificCluster: Status.Success,
  },
  _clusters: [],
  current: null,
}

const nonVisibleClusterStates = [
  'FAILED',
  'DESTROYED',
  'DESTROY_REQUESTED',
  'PENDING_DESTROY',
  'PLANNING_DESTROY',
  'DESTROYED_AFTER_FAILURE',
]

const nonActiveClusterStates = [...nonVisibleClusterStates, 'DESTROYING']

export const ClusterContext = React.createContext<ClusterStore>({
  storeState: initialState,
  clusters: [],
} as unknown as ClusterStore)

export function ClusterProvider({ children }: React.PropsWithChildren) {
  const { storeState: orgState } = React.useContext(OrganizationContext)
  const accountStore = React.useContext(AccountContext)

  const activeOrgId = orgState?.activeOrg?.data?.id
  const { fetchCall } = useFetchCall()

  const [storeState, setStoreState] = React.useState<StoreState>(initialState)

  React.useEffect(() => {
    if (activeOrgId) {
      getClusters()
    }
  }, [activeOrgId])

  async function getClusters() {
    setStoreState((state) => ({
      ...state,
      status: { ...state.status, get: Status.Loading },
      _clusters: [],
    }))

    const results = await Promise.allSettled([
      fetchCall({
        path: 'clusters',
        version: ApiVersion.V1,
      }),
      fetchCall({ path: `orgs/${activeOrgId}/clusters` }),
    ])

    const _clusters: Array<Cluster> = results
      .filter((data) => data.status === 'fulfilled')
      .flatMap((list) => {
        const isV2Cluster = Array.isArray(list.value)
        const values = isV2Cluster ? list.value : list.value.items
        return values.map((cluster: Cluster) => ({
          ...cluster,
          id: cluster?.id ?? cluster?.uuid,
          clusterUrl: { url: cluster.url ?? cluster?.endpoint },
        }))
      })

    setStoreState((state) => ({
      ...state,
      status: { ...state.status, get: Status.Success },
      _clusters,
    }))
  }

  async function createCluster(payload: ClusterCreateConfig) {
    try {
      setStoreState((state) => ({
        ...state,
        status: { ...state.status, post: Status.Loading },
      }))
      const response = await fetchCall({
        path: `clusters`,
        version: ApiVersion.V1,
        payload,
      })
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        status: { ...state.status, post: Status.Error },
      }))
    }
  }

  async function deleteCluster(clusterId: string) {
    setStoreState((state) => ({
      ...state,
      status: { ...state.status, delete: Status.Loading },
    }))

    try {
      const isServerless =
        storeState._clusters
          .find((cluster) => cluster.uuid === clusterId)
          ?.type.toLowerCase() === starterPackage.name

      const data = isServerless
        ? { version: ApiVersion.V1, path: `clusters/${clusterId}` }
        : {
            version: ApiVersion.V2,
            path: `orgs/${activeOrgId}/clusters/${clusterId}`,
          }

      await fetchCall({ ...data, method: HTTPMethod.DELETE })
      setStoreState((state) => ({
        ...state,
        status: { ...state.status, delete: Status.Success },
        _clusters: state._clusters.filter(
          (cluster) => cluster.uuid !== clusterId
        ),
      }))
    } catch (error) {
      setStoreState((state) => ({
        ...state,
        status: { ...state.status, delete: Status.Error },
      }))
    }
  }

  async function getSpecificCluster(clusterId: string, serverless: boolean) {
    const version = serverless ? ApiVersion.V1 : ApiVersion.V2
    const path = serverless
      ? `clusters/${clusterId}/details`
      : `orgs/${activeOrgId}/clusters/${clusterId}`

    type Type<A> = A extends true
      ? Cluster
      : ApiaryAPI.paths['/api/v2/orgs/{orgId}/clusters/{clusterId}']['get']['responses']['200']['content']['application/json']

    const response = await fetchCall<Type<typeof serverless>>({ path, version })

    if (serverless) {
      setStoreState((state) => ({
        ...state,
        status: { ...state.status, specificCluster: Status.Success },
        current: response as Cluster,
      }))
    } else {
      setStoreState((state) => ({
        ...state,
        status: { ...state.status, specificCluster: Status.Success },
        current: response as Type<true>,
      }))
    }
  }

  const clusters = React.useMemo(() => {
    return storeState._clusters.reduce(
      (acc, curr) => {
        if (!nonVisibleClusterStates.includes(curr.state)) {
          const clusterType = (clusterPackages.find(
            (cPackage) => cPackage.name === curr.type
          )?.clusterType ?? curr.plan) as ClusterPackageConfigType

          const serverlessClusterForNonOrgOwner =
            !accountStore?.storeState.accountData.isOrgOwner && !curr.plan

          if (serverlessClusterForNonOrgOwner) {
            return acc
          }

          if (!acc[clusterType]) {
            acc[clusterType] = [
              {
                ...curr,
                clusterType,
                state: curr?.state ?? curr?.status?.state,
                started: (curr?.started || curr?.start_time)!,
                type: (curr.type || curr.plan)!.toLowerCase(),
              },
            ]
          } else {
            acc[clusterType] = [
              ...acc[clusterType],
              {
                ...curr,
                clusterType,
                state: curr?.state ?? curr?.status?.state,
                started: (curr?.started || curr?.start_time)!,
                type: (curr.type || curr.plan)!.toLowerCase(),
              },
            ]
          }
        }

        return acc
      },
      {} as Record<
        ClusterPackageConfigType,
        Array<Cluster & { clusterType: ClusterPackageConfigType }>
      >
    )
  }, [storeState, accountStore?.storeState.accountData])

  const value = React.useMemo(
    () => ({
      getClusters,
      createCluster,
      deleteCluster,
      getSpecificCluster,
      clusters,
      storeState,
    }),
    [storeState, clusters]
  )

  return (
    <ClusterContext.Provider value={value}>{children}</ClusterContext.Provider>
  )
}
