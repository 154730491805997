import { createAuth0Client } from '@auth0/auth0-spa-js'
import VueAnnouncer from '@vue-a11y/announcer'
import * as LDClient from 'launchdarkly-js-client-sdk'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueClipboard from 'vue3-clipboard'

import { useApplicationStore } from '@/store/applicationStore'

import { isDevelop, isProduction, isStaging } from '@/utils/environments'
import { useOrbital } from '@/utils/hooks/useOrbital'
import { saveUTMParameters } from '@/utils/localStorage/utmStorage'
import {
  GTMMarketingEventName,
  googleTagManager,
} from '@/utils/tracking/googleTagManager/useGoogleTagManager'

import App from './App.vue'
import { $http, $httpV2 } from './clients/consoleAxiosClient'
import { setupI18n } from './i18n'
import {
  initAuth0 as storeAuth0ClientInMemory,
  Auth0User,
  getAuth0User,
  getAuth0Token,
  handleAuth0RedirectCallback,
} from './plugins/auth0'
import Vuetify from './plugins/vuetify'
import { setupRouter } from './router'
import { useRefiner } from './utils/hooks/useRefiner'

const app = createApp(App)

app.use(VueAnnouncer)
app.use(Vuetify)
app.use(createPinia())

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

window._hsq = window._hsq || []

const isVerifyEmailRoute = window.location.href.includes('/verify-email')

$http.get('settings/frontend').then((response) => {
  window.hmqcConfig = response.data.items
  main()
})

async function main() {
  await installMockServiceWorker()

  saveUTMParameters(new URL(window.location.href))
  const i18n = setupI18n('en-US')

  const { origin } = window.location
  const { auth0_domain, auth0_clientid, auth0_audience } = window.hmqcConfig

  const auth0Client = await createAuth0Client({
    domain: auth0_domain,
    clientId: auth0_clientid,
    cacheLocation: 'memory',
    authorizationParams: {
      audience: auth0_audience,
      redirect_uri: origin,
    },
  })

  await storeAuth0ClientInMemory(auth0Client)
  await handleAuth0RedirectCallback()

  try {
    if (!isVerifyEmailRoute) {
      googleTagManager.sendEvent(
        GTMMarketingEventName.HiveMQCloud__General_anyViewVisited
      )

      const auth0User = await getAuth0User()

      if (auth0User) {
        await initHttpClients()
        await useApplicationStore().initStores()

        initHubspot(auth0User)
        initMixpanel(auth0User)
        initRefiner(auth0User)
        initLaunchDarkly(auth0User)
        initOrbital()
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console -- we want to log the error
    console.error(error)
  }

  const router = setupRouter()

  // Auth has to be loaded and registered before router to ensure that we always have auth data in
  // place when "beforeEach" route guard is called.
  app.use(i18n)
  app.use(router)
  app.mount('#app')
}

async function installMockServiceWorker() {
  if (isDevelop && window.location.search.includes('msw')) {
    try {
      const { setupConsoleMockClient } = await import(
        '@/utils/mocks/console/setupConsoleMockClient'
      )

      setupConsoleMockClient(app)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

async function initHttpClients() {
  const token = await getAuth0Token()
  const httpClients = [$http, $httpV2]

  httpClients.forEach((httpClient) => {
    httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token
  })
}

function initMixpanel(auth0User: Auth0User) {
  let mixpanelApiToken = window.hmqcConfig.mixpanel_api_token
  if (!mixpanelApiToken) {
    return
  }

  if (isDevelop) {
    window.mixpanel.init(mixpanelApiToken, {
      api_host: 'https://api.mixpanel.com',
    })
  } else {
    window.mixpanel.init(mixpanelApiToken)
  }

  const { sub, email } = auth0User!

  window.mixpanel.identify(sub)
  window.mixpanel.people.set({
    $email: email, // only special properties need the $
  })
}

function initRefiner(auth0User: Auth0User) {
  const { setProject, identifyUser } = useRefiner()
  const { sub, email } = auth0User!

  setProject()
  identifyUser(sub!, email!)
}

function initHubspot(auth0User: Auth0User) {
  if (isProduction) {
    window._hsq = window._hsq || []
    window._hsq.push(['refreshPageHandlers'])
    window._hsq.push([
      'identify',
      {
        email: auth0User.email,
        id: auth0User.sub,
      },
    ])
  }
}

function initLaunchDarkly(auth0User: Auth0User) {
  const envKey = (() => {
    if (isStaging || isDevelop) {
      return '63da749558f0541261ff33ae'
    }

    if (isProduction) {
      return '63da749558f0541261ff33af'
    }

    return ''
  })()

  window.launchDarkly = LDClient.initialize(envKey, {
    kind: 'user',
    key: auth0User?.sub ?? '',
    email: auth0User?.email ?? '',
  })
}

function initOrbital() {
  const orbitalController = useOrbital()

  const TWO_MINUTES = 120_000
  const FIFTEEN_SECONDS = 15_000

  orbitalController.setupTimer({
    openAfter: TWO_MINUTES,
    retryAfter: FIFTEEN_SECONDS,
  })
}
