/**
 * These types will be replaced by a OpenAPI generated file
 * For now we can deactivate the unused-vars rules,
 * since it is a reflection of a possible server return type.
 */
/* eslint-disable no-unused-vars */

import { components } from '../generated/openapi/apiary'

export type Cluster = Readonly<{
  uuid: string
  name: string
  state: ClusterState
  createdAt: string
  started: string
  stopped: string
  url: string
  type: string
  providerType: string
  isFreeTrial: boolean
  version: string
  additionalUrls: string[]
  location?: string
  port?: number
  websocketPort?: number
  paymentType?: components['schemas']['Cluster']['paymentType']
  urls?: {
    rest?: string
    controlCenter?: string
    clusterUrl?: string
  }
  api_gw_host?: string
  clientAuthMethod: string
  plan?: string
  // v2
  id?: string
  endpoint?: string
  status?: { state: ClusterState }
  start_time?: string

  // custom
  clusterUrl?: { url: string }
}>

export enum ClusterState {
  NEW = 'NEW',
  PENDING = 'PENDING',
  PLANNING = 'PLANNING',
  CREATING = 'CREATING',
  INITIALIZING = 'INITIALIZING',
  FAILED = 'FAILED',
  LOCKED = 'LOCKED',
  /*
   * We know that the backend will set the state to LOCKED in a couple of seconds but need to
   * wait for the actual state to be set in the backend.
   * This is a temporary state that will be removed once the cluster endpoint is hit after the timeout.
   *
   * Use this state to show a loading spinner and disable the UI.
   *
   * !IMPORTANT - This state is not persisted in the backend.
   */
  LOCKED_OPTIMISTIC = 'LOCKED_OPTIMISTIC',
  DONE = 'DONE',
  DESTROY_REQUESTED = 'DESTROY_REQUESTED',
  PENDING_DESTROY = 'PENDING_DESTROY',
  PLANNING_DESTROY = 'PLANNING_DESTROY',
  DESTROYING = 'DESTROYING',
  DESTROYED = 'DESTROYED',
  DESTROYED_AFTER_FAILURE = 'DESTROYED_AFTER_FAILURE',
  ERROR = 'ERROR',
}

export enum ClusterTypeName {
  FREE = 'Free',
  PAYG = 'Pay As You Go',
  // TODO: Rename to the real product name
  STARTER = 'Starter',
  /** @deprecated - Previous package that cannot be created anymore */
  STANDARD = 'Standard',
  /** @deprecated - Previous package that cannot be created anymore */
  BUSINESS = 'Business Critical',
}

export enum ClusterType {
  FREE = 'shared_multi_tenant',
  PAYG = 'shared_multi_tenant_paid',
  // @deprecated - Previous V1 package
  STANDARD = 'ec2_single',
  // TODO: Rename to the real product name
  STARTER = 'starter',
  DEDICATED = 'dedicated',
}

export enum ClientAuthMethod {
  ESE = 'ese',
  Certificates = 'certificates',
  JWT = 'jwt',
}

export const clientAuthMethodName: Record<ClientAuthMethod, string> = {
  [ClientAuthMethod.ESE]: 'Access Credentials',
  [ClientAuthMethod.Certificates]: 'Certificate Authentication',
}

export enum CloudProvider {
  NULL = '',
  AWS = 'aws',
  AZURE = 'azure',
  GCP = 'gcp',
}

export type Usage = {
  readonly sessions: number
  readonly traffic: number
}

// Cloud Provider Constants
/** @deprecated - use `CloudProvider` enum type to get the expected output */
export const AWS = 'aws'
/** @deprecated - use `CloudProvider` enum type to get the expected output */
export const AZURE = 'azure'

/** @deprecated - use i18n to get that output */
export const AWS_FULL_LENGTH = 'Amazon Web Services'
/** @deprecated - use i18n to get that output */
export const AZURE_FULL_LENGTH = 'Microsoft Azure'
