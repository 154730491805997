import { whenever } from '@vueuse/core'
import { defineStore } from 'pinia'
import { nextTick, UnwrapRef } from 'vue'

import { getEnvironment } from '@/utils/environments'
import { useGetFeatureFlags } from '@/utils/hooks/useGetFeatureFlags'

export const DEV_ENV_ONLY = 'dev-env-only'

type FeatureFlag = UnwrapRef<
  ReturnType<typeof useGetFeatureFlags>['state']
>[number]

/**
 * All know feature flags that the backend provides
 * us from the /v2 api.
 * Helpfully while working with mocks and corresponding resolvers.
 */
export enum V2ApiFeatureFlag {
  /**
   * Disables mutations of PaaS products
   * includes:
   * `POST`, `PUT` and `DELETE` requests
   */
  killSwitch = 'kill-switch',
  kafkaExtensionCanary = 'kafka_ext_canary',
  multiRegionSupport = 'multi-region-support',
  restApi = 'rest-api',
  customDnsNames = 'custom-dns-names',
  organisationManagement = 'org-management',
  troubleShootingLogs = 'troubleshooting-logs',
}

export enum ABTestFeatureFlag {
  signupJourney = 'sign-up-journey',
  amazonKinesis = 'amazon-kinesis',
  dataLakeBanner = 'data-lake-banner',
  genericKafkaIntegration = 'generic-kafka',
  yearlyBillingPromotion = 'upfront_payment_enabled',
}

export const useFeatureFlagStore = defineStore('feature-flags', {
  state: () => {
    return {
      featureFlags: [] as FeatureFlag[],
    }
  },
  getters: {
    isMigrationActive(): boolean {
      return this.featureFlags.includes('MigrationWarning')
    },
    isPaaSKillSwitchEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.killSwitch)
    },
    isKafkaExtensionCanaryEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.kafkaExtensionCanary)
    },
    isABSignupJourneyEnabled(): boolean {
      return this.featureFlags.includes(ABTestFeatureFlag.signupJourney)
    },
    isMultiRegionEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.multiRegionSupport)
    },
    isRestApiEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.restApi)
    },
    isCustomDnsNamesEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.customDnsNames)
    },
    isAmazonKinesisEnabled(): boolean {
      return this.featureFlags.includes(ABTestFeatureFlag.amazonKinesis)
    },
    isDataLakeBannerEnabled(): boolean {
      return this.featureFlags.includes(ABTestFeatureFlag.dataLakeBanner)
    },
    isOrganisationManagementEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.organisationManagement)
    },
    isGenericKafkaIntegrationEnabled(): boolean {
      return this.featureFlags.includes(
        ABTestFeatureFlag.genericKafkaIntegration
      )
    },
    isYearlyBillingPromotionEnabled(): boolean {
      return this.featureFlags.includes(
        ABTestFeatureFlag.yearlyBillingPromotion
      )
    },
    isOnboardingSidebarEnabled(): boolean {
      return this.featureFlags.includes('user-onboarding')
    },
    isTroubleShootingLogsEnabled(): boolean {
      return this.featureFlags.includes(V2ApiFeatureFlag.troubleShootingLogs)
    },
  },
  actions: {
    async loadFeatureFlags() {
      return new Promise((resolve) => {
        if (getEnvironment() === 'development') {
          this.featureFlags = [...this.featureFlags, DEV_ENV_ONLY]
        }

        const { state, isReady } = useGetFeatureFlags()

        whenever(isReady, async () => {
          this.featureFlags = [
            ...new Set([...this.featureFlags, ...state.value]),
          ]

          /**
           * Await nextTick so the featureFlags can settle
           * and are available for next network requests
           */
          await nextTick()

          resolve(undefined)
        })
      })
    },
    /**
     *
     * @param name One or many feature flag names.
     * @returns True if all feature flags defined in names are enabled.
     */
    hasActiveFeatureFlag(name: V2ApiFeatureFlag | string): boolean {
      return this.featureFlags.includes(name)
    },
    /**
     * Reload feature flags from the backend api
     */
    async reloadFeatureFlags(): Promise<void> {
      this.featureFlags = []
      await this.loadFeatureFlags()
    },
  },
})
